<template>
  <div class="container">
    <PublicHeader :totalName="$t('msgbank').bankCard" />
    <div class="card">
      <div v-if="IsBindBank & IsUpdate">
        <div class="label">{{ $t('msgbank').CardUserName }}</div>
        <van-field v-model="userInfo.CardUserName" :placeholder="$t('msgbank').CardUserName" />
        <div class="label">{{ $t('msgbank').BankName }}</div>
        <van-field v-model="userInfo.BankName" :placeholder="$t('msgbank').BankName" />
        <div class="label">{{ $t('msgbank').OpenBank }}</div>
        <van-field v-model="userInfo.OpenBank" :placeholder="$t('msgbank').OpenBank" />
        <div class="label">{{ $t('msgbank').CardNo }}</div>
        <van-field v-model="userInfo.CardNo" :placeholder="$t('msgbank').CardNo" />
        <div class="btn" @click="submitBank">{{ $t('moneyAddr').submit }}</div>
      </div>
      <div v-if="IsBindBank & !IsUpdate">
        <div class="label">{{ $t('msgbank').CardUserName }}</div>
        <van-field v-model="bankInfo.CardUserName" readonly />
        <div class="label">{{ $t('msgbank').BankName }}</div>
        <van-field v-model="bankInfo.BankName" readonly />
        <div class="label">{{ $t('msgbank').OpenBank }}</div>
        <van-field v-model="bankInfo.OpenBank" readonly />
        <div class="label">{{ $t('msgbank').CardNo }}</div>
        <van-field v-model="bankInfo.CardNo" readonly />
        <!-- <div class="btn2" @click="IsUpdate = true"> {{ $t('msgbank').modify }} </div> -->
      </div>
      <div v-if="!IsBindBank" class="noBank">
        <van-icon name="add-o" size="30" @click="IsBindBank = true; IsUpdate = true" />
      </div>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      IsUpdate: false,
      IsBindBank: false,
      bankInfo: {},
      userInfo: {}
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  methods: {
    async getUserInfo() {
      let res = await userApi.userInfo()
      this.userInfo = res
      if (res.IsBindBank) {
        this.IsBindBank = true
        const bankInfo = {
          CardUserName: this.userInfo.CardUserName,
          BankName: this.userInfo.BankName,
          OpenBank: this.userInfo.OpenBank,
          CardNo: this.showBankCard(this.userInfo.CardNo)
        }
        this.bankInfo = bankInfo
      } else this.IsBindBank = false
    },
    async submitBank() {
      if (this.userInfo.CardUserName === '') {
        this.$toast(this.$t('msgbank').inputTips)
        return false
      }
      if (this.userInfo.BankName === '') {
        this.$toast(this.$t('msgbank').inputTips)
        return false
      }
      if (this.userInfo.OpenBank === '') {
        this.$toast(this.$t('msgbank').inputTips)
        return false
      }
      if (this.userInfo.CardNo === '') {
        this.$toast(this.$t('msgbank').inputTips)
        return false
      }
      const form = {
        CardUserName: this.userInfo.CardUserName,
        BankName: this.userInfo.BankName,
        OpenBank: this.userInfo.OpenBank,
        CardNo: this.userInfo.CardNo
      }
      await userApi.updateBank(form)
      this.$toast(this.$t('msgbank').submitSuccess)
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000)
    },
    showBankCard(str) {
      var masked = ''
      if (str.length > 8) {
        masked = str.slice(0, 4) + '********' + str.slice(-4)
      } else if (str.length > 4) {
        masked = str.slice(0, 4) + '****' + str.slice(-4)
      } else {
        masked = str.replace(/./g, '*')
      }
      return masked
    }
  },
  mounted() {
    this.getUserInfo()
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/mixin.styl';

.van-field {
  border-radius: 5px;
}

.container {
  width: 100%;
  background-color: #000000;
  font-size: 12px;
  overflow-x: hidden;
  height: 100vh;
  padding-top: 48.5px;
  text-align: left;

  .card {
    padding: 10px;
    height: 150px;
    border-radius: 10px;
    background: #000;
    margin: 0 10px;

    .label {
      margin: 20px 0 10px 0;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }

    .btn {
      margin: 50px auto;
      height: 40px;
      background: #8e1473;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      border-radius: 30px;
    }

    .btn2 {
      margin: 50px auto;
      height: 40px;
      background: #696969;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      border-radius: 30px;
    }
  }

  .noBank {
    margin-top: -17px;
    flex-column();
    height: 100%;
  }

  .info {
    margin: 20px;
    color: #e60d0d;
  }
}
</style>
